.el-form-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
}

.el-form-item__label {
  max-width: 100px;
  flex: 1;
}

.el-radio-group.bankradio {
  display: flex;
  flex: 10;
  margin-left: 20px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

label.el-radio {
  width: auto;
  padding: 5px 20px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

label.el-radio:hover {
  border-color: blue;
}

.el-radio img {
  width: 20px;
  margin: 2px 5px 0 5px;
}

.rechargenotice {
  line-height: 2.6rem;
  color: red;
}

.rechargenotice .prompt2 {
  color: blue;
}

.el-button--primary {
  background-color: rgb(95, 95, 245);
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
}

.el-radio__input {
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1;
  outline: 0;
  position: relative;
  display: inline-block;
}

.el-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 10px;
  left: 6px;
  height: 14px;
  width: 14px;
  background-color: #eee;
  border-radius: 50%;
}

input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkmark:after {
  top: 5px;
  left: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
}

.el-form-item__momo {
  display: flex;
  align-items: center;
  justify-content: center;
}
