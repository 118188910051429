header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  background-color: $c1;
  color: #fff;
  z-index: 999;
  transition: 0.3s ease-in-out;
  &.scroll {
    box-shadow: 0 0 1rem 0 rgba(#000, 0.32);
  }
}
.top-header {
  // background: linear-gradient(to top, #092a63 0, #31449e 70%, #254290 100%);
  background-color: $c-primary;
  border-bottom: 1px solid #126691;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}
.d-nav {
  > ul {
    > li {
      display: inline-block;
      margin: 0 0.7rem;
      width: 7.8rem;
      border-top: 2px solid transparent;
      transition: 0.4s ease-in-out;
      > a {
        display: block;
        padding: 4.8rem 0 2rem 0;
        font-size: 1.3rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        font-family: $f2;
        transition: 0.3s ease-in-out;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 1.8rem;
          width: 2.6rem;
          height: 2.6rem;
          background-image: url("../../images/icon-home.png");
          background-repeat: no-repeat;
          background-size: auto;
          background-position: center;
          filter: brightness(0) invert(1);
          transition: 0.3s ease-in-out;
        }
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 11px solid #faf8a1;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s ease-in-out;
        }
        > span {
          display: block;
          text-align: center;
          @include set-line(2, 1.5em);
        }
      }
      &.hot {
        &:after {
          content: "";
          position: absolute;
          width: 4.2rem;
          height: 2rem;
          left: 50%;
          transform: translateX(-50%);
          bottom: 2rem;
          background: url("../../images/icon-hot.png") no-repeat center;
        }
      }
      &.lottery {
        > a {
          &:before {
            background-image: url("../../images/icon-lottery.png");
          }
        }
      }
      &.live {
        > a {
          &:before {
            background-image: url("../../images/icon-live.png");
          }
        }
      }
      &.slots {
        > a {
          &:before {
            background-image: url("../../images/icon-slots.png");
          }
        }
      }
      &.by {
        > a {
          &:before {
            background-image: url("../../images/icon-by.png");
          }
        }
      }
      &.sport {
        > a {
          &:before {
            background-image: url("../../images/icon-sport.png");
          }
        }
      }
      &.qp {
        > a {
          &:before {
            background-image: url("../../images/icon-qp.png");
          }
        }
      }
      &.dj {
        > a {
          &:before {
            background-image: url("../../images/icon-dj.png");
          }
        }
      }
      &.es {
        > a {
          &:before {
            background-image: url("../../images/esport-icon.png");
          }
        }
      }
      &.activity {
        > a {
          &:before {
            background-image: url("../../images/icon-activity.png");
          }
        }
      }
      &.servicer {
        > a {
          &:before {
            background-image: url("../../images/icon-servicer.png");
          }
        }
      }

      &.active,
      &:hover {
        border-color: #faf8a1;
        &:before {
          width: 100%;
          left: 0;
        }
        > a {
          color: #faf8a1;
          &:before {
            filter: brightness(1) invert(0);
          }
          &:after {
            bottom: 0.6rem;
            opacity: 1;
            visibility: visible;
          }
        }
      }
      &:hover {
        > ul {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
      > ul {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        z-index: 999999;
        background-color: blue;
        left: 0;
        width: 100%;
        top: 100%;
        background-color: rgba(0, 16, 81, 0.9);
        padding: 0 1.5rem;
        border: 1px solid #344162;
        opacity: 0;
        visibility: hidden;
        transform: translateY(1rem);
        transition: 0.4s ease-in-out;
        > li {
          flex: 0 0 16.6666666667%;
          &.official {
            position: relative;
            &:before {
              content: "";
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
              z-index: 9;
              width: 7.3rem;
              height: 2.6rem;
              background: url("../../images/certificate-s.svg") center/auto 22px
                no-repeat;
            }
          }
          > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-bottom: 1.5rem;
            text-align: center;

            span.bonus {
              text-transform: uppercase;
            }

            p.bonus {
              margin-top: 10px;
            }

            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 0;
              width: 100%;
              background: linear-gradient(
                to bottom,
                #0081eb 0,
                rgba(0, 0, 0, 0.3) 100%
              );
              transition: 0.3s ease-in-out;
              z-index: -1;
            }
            &:hover {
              &:before {
                height: 100%;
              }
            }
          }
          .bonus {
            .debate {
              color: #ff0;
            }
          }
        }
        &.sub-text {
          padding: 1rem 2rem;
          min-height: 48rem;
          flex-wrap: nowrap;
          > li {
            flex: 0 0 auto;
            padding: 0 1rem;
            &:first-child {
              padding-left: 0;
            }
            &:not(:last-child) {
              border-right: 1px solid #333;
            }
            > ul {
              > li {
                margin-bottom: 2rem;
                > a {
                  text-align: left;
                  color: #fff;
                  transition: 0.2s;
                  font-size: 1.4rem;
                  text-transform: capitalize;
                  background-size: 0 0;
                  transition: 0.3s ease-in-out;
                  padding: 0.5rem;
                  span {
                    display: inline-block;
                    position: relative;
                  }
                  &.hot {
                    span {
                      &:after {
                        content: "";
                        position: absolute;
                        width: 4.2rem;
                        height: 2rem;
                        right: -1.5rem;
                        bottom: calc(100% - 0.5rem);
                        background: url(../../images/icon-hot.png) no-repeat
                          center;
                      }
                    }
                  }
                  &:hover {
                    background: linear-gradient(
                      to bottom,
                      #0081eb 0,
                      rgba(0, 22, 40, 0.2) 90%
                    );
                    background-size: 100% 100%;
                  }
                }
              }
            }
          }
          .head {
            display: flex;
            color: #ffab03;
            margin-bottom: 1.5rem;
            font-size: 1.3rem;
            .title {
              margin-right: 1.5rem;
              white-space: nowrap;
              text-transform: capitalize;
            }
            select {
              background-color: transparent;
              color: #fff;
              border: 1px solid #ffab03;
              color: #ffab03;
              padding-left: 0.3rem;
              padding-right: 0.3rem;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

.bottom-header {
  // background-color: #2161b4;
  background-color: $c-primary;
  color: #fff;
  padding: 1rem 0;
}
.member-login {
  display: flex;
  align-items: center;
  justify-content: center;
  select {
    height: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #7ebffc;
    border-radius: 0.6rem;
    box-shadow: inset 0 1px 0 rgb(0 255 161 / 20%),
      inset 0 -1.5px 0 rgb(0 0 0 / 10%);
    padding: 0 1rem 0.1rem 3.4rem;
    position: relative;
    margin-right: 1rem;
    transition: 0.3s ease-in-out;
  }
  .form-group {
    margin-bottom: 0;
    flex: 0 0 13.2rem;
    margin-right: 1rem;
    position: relative;
    &:before {
      content: "\f007";
      position: absolute;
      left: 1.2rem;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(#fff, 0.5);
    }
    &.password {
      &:before {
        content: "\f30d";
      }
    }
    &.valid {
      flex: 0 0 17.5rem;
      margin-right: 2rem;
      &:before {
        content: "\f2f7";
      }
      .form-control {
        padding-right: 2rem;
      }
      .valid-code {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        width: 4.8rem;
        height: 1.6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .form-control {
    background-color: #0e4c9e;
    box-shadow: 0 0.5px 0 #2a71d1 !important;
    border-radius: 6rem;
    height: 3rem;
    color: #fff;
    font-size: 1.3rem;
    padding-left: 3rem;
    padding-right: 1rem;
    border: none;
    padding-bottom: 0.5rem;
    &::placeholder {
      color: rgba(#fff, 0.5);
    }
  }
}
.btn-login {
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #01a76a;
  border-radius: 1.6rem;
  box-shadow: inset 0 1px 0 rgb(0 255 161 / 20%),
    inset 0 -1.5px 0 rgb(0 0 0 / 10%);
  padding: 0 1rem 0.1rem 3.4rem;
  position: relative;
  margin-right: 1rem;
  transition: 0.3s ease-in-out;
  &:before {
    content: "\f2f6";
    position: absolute;
    left: 1.2rem;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    top: 50%;
    transform: translateY(-50%);
  }
  &.signup {
    background-color: #0085ff;
    &:before {
      content: "\f234";
    }
  }
  &.try {
    background-color: #21a0ff;
    &:before {
      content: "\f8bc";
    }
  }
  &:hover {
    background-color: darken(#01a76a, 10%);
    &.signup {
      background-color: darken(#0085ff, 10%);
    }
    &.try {
      background-color: darken(#21a0ff, 10%);
    }
  }
}
.btn-forget {
  margin-left: 1rem;
  &:hover {
    text-decoration: underline;
  }
}
.center-header {
  // background-color: #092648;
  background-color: $c-secondary__color;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    li {
      display: block;
      position: relative;
      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: -1.5rem;
          width: 1px;
          height: 80%;
          top: 10%;
          background-color: #126691;
        }
      }
      a {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        padding: 0.5rem 0;
        .title {
          text-transform: initial;
          color: #efde8e;
          display: inline-flex;
          align-items: center;
          margin-right: 1rem;
          img {
            margin-right: 0.8rem;
          }
        }
        .day {
          display: inline-block;
          margin-right: 0.6rem;
          padding-right: 0.6rem;
          line-height: 1;
          border-right: 1px solid #fff;
        }
        .hour {
          margin-right: 1rem;
        }
      }
    }
  }
  .count-online {
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 0.6rem;
      flex: 0 0 1.7rem;
      height: 1.9rem;
    }
    .box-count {
      display: inline-flex;
      align-items: center;
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 2.6rem;
        padding: 0 0.5rem;
        background: url("../../images/number-box.png") no-repeat center;
        background-size: 100% 100%;
        margin: 0 0.2rem;
      }
    }
  }
}

.icon-menu {
  display: none;
}

ul.center {
  justify-content: center;
  align-items: center;
}
